import React from "react";

import styles from "./table.module.scss";

import { Section } from "../section";

export const Table: React.FC = ({ children }) => (
  <Section>
    <div className={styles.wrapper}>
      <table>
        <tbody>{children}</tbody>
      </table>
    </div>
  </Section>
);
