import React from "react";
import cn from "classnames";

import styles from "./title.module.scss";

type TitleProps = {
  doubled?: boolean;
  inverted?: boolean;
};

export const Title: React.FC<TitleProps> = ({
  children,
  doubled,
  inverted,
}) => (
  <div className={cn(styles.wrapper, inverted && styles.inverted)}>
    {doubled && <div className={styles.background}>{children}</div>}
    <div className={styles.label}>{children}</div>
  </div>
);
