import React from "react";
import cn from "classnames";

import styles from "./section.module.scss";

export const Section: React.FC<{ narrow?: boolean }> = ({
  children,
  narrow,
}) => (
  <div className={styles.wrapper}>
    <div className={cn(styles.innerWrapper, narrow && styles.narrow)}>
      {children}
    </div>
  </div>
);
