import React from "react";

import { Layout } from "../components/shared/layout";
import { Helmet } from "../components/shared/helmet";
import { PageTitle } from "../components/shared/page-title";

import { Table } from "../components/shared/table";

export default () => (
  <Layout>
    <Helmet title="Impressum" />
    <PageTitle>IMPRESSUM</PageTitle>
    <Table>
      <tr>
        <td>Seitenbetreiber:</td>
        <td>Detlef Krengel</td>
      </tr>
      <tr>
        <td>Email:</td>
        <td>
          <a href="mailto:krengelsys@t-online.de">krengelsys@t-online.de</a>
        </td>
      </tr>
      <tr>
        <td>Telefon:</td>
        <td>0157 - 70339184</td>
      </tr>
      <tr>
        <td>Adresse:</td>
        <td>
          Marktstrasse 58
          <br />
          47798 Krefeld
        </td>
      </tr>
      <tr>
        <td>{"\u00A0"}</td>
      </tr>
      <tr>
        <td>Konzeption und Realisation:</td>
        <td>
          <a
            href="https://kretschel.media"
            target="_blank"
            rel="noopener noreferrer"
          >
            Kretschel Media
          </a>
        </td>
      </tr>
    </Table>
  </Layout>
);
